import Swiper from 'swiper/bundle';


//home

////about
var swiper = new Swiper('.swiper-about', {
  slidesPerView: 1,
  spaceBetween: 0,
  pagination: {
    el: '.swiper-pagination-about',
    clickable: true,
  },
});




////product
var swiper2 = new Swiper('.swiper-product', {
  slidesPerView: 1,
  spaceBetween: 0,
  roundLengths: true,
  breakpoints: {
    1065: {
      slidesPerView: 4,
      spaceBetween: 0
    },
    767: {
      slidesPerView: 2,
      slidesPerColumn: 3,
    }
  },
  navigation: {
    nextEl: '.swiper-product-next',
    prevEl: '.swiper-product-prev',
  },
  pagination: {
    el: '.swiper-pagination-product',
    clickable: true,
  },
});


////product
var swiper2 = new Swiper('.swiper-product-2', {
  slidesPerView: 1,
  spaceBetween: 0,
  roundLengths: true,
  breakpoints: {
    1065: {
      slidesPerView: 3,
      spaceBetween: 0
    },
    767: {
      slidesPerView: 1,
      slidesPerColumn: 3,
    }
  },
  navigation: {
    nextEl: '.swiper-product-next',
    prevEl: '.swiper-product-prev',
  },
  pagination: {
    el: '.swiper-pagination-product',
    clickable: true,
  },
});







var swiperScreen = new Swiper('.swiper-screen', {
  direction: 'vertical',
  spaceBetween: 0,
  speed: 1000,
  mousewheel: true,
  fadeEffect: {
    crossFade: true
  },
  hashNavigation: {
    watchState: true,
  },
  on: {
    init: function(event) {
      if(event.activeIndex === 0) {
        $('.btn-next').addClass('active');
      } else{
        $('.btn-next').removeClass('active');
      }
    },
    slideChange: function(event) {
      $('.product--long .swiper-slide').removeClass('top');
      $('.product--flat .swiper-slide').removeClass('top');
      $('.product--materials .swiper-slide').removeClass('top');
      $('.map__img').removeClass('top');
      $('.menu-nav li').removeClass('active');

      if(event.activeIndex === 0) {
        $('.btn-next').addClass('active');
      } else{
        $('.btn-next').removeClass('active');
      }
      if(event.activeIndex === 1) {
        $('.menu-nav li[data-menuanchor="about"]').addClass('active');
      } else if(event.activeIndex === 2) {
        $('.product--flat .swiper-slide').addClass('top');
        $('.menu-nav li[data-menuanchor="services"]').addClass('active');
      } else if(event.activeIndex === 3) {
        $('.product--long .swiper-slide').addClass('top');
        $('nav .menu-nav li[data-menuanchor="services"]').addClass('active');
        $('.menu__content .menu-nav li[data-menuanchor="services2"]').addClass('active');
      } else if(event.activeIndex === 4) {
        $('.product--materials .swiper-slide').addClass('top');
        $('nav .menu-nav li[data-menuanchor="services"]').addClass('active');
        $('.menu__content .menu-nav li[data-menuanchor="services3"]').addClass('active');
      } else if(event.activeIndex === 5) {
        $('.map__img').addClass('top');
        $('.menu-nav li[data-menuanchor="map"]').addClass('active');
      } else if(event.activeIndex === 6) {
        $('.menu-nav li[data-menuanchor="contact"]').addClass('active');
      }


      $('.btn-next').removeClass('up').addClass('down');
      if(event.activeIndex === 3) {
        $('#menu li[data-menuanchor="services"]').addClass('active');
      } else if(event.activeIndex === 4) {
        $('#menu li[data-menuanchor="services"]').addClass('active');
      }
      if(event.activeIndex === 6) {
        $('.btn-next').removeClass('down').addClass('up');
      }
    },
  },
});


$('.btn-next').on('click', function() {
  if($(this).hasClass('down')) {
    swiperScreen.slideNext();
  } else{
    swiperScreen.slideTo(0);
  }
});
$('.header__logo').on('click', function() {
  swiperScreen.slideTo(0);
});


$('.menu-nav li').on('click', function() {
  let index = $(this).attr('data-id');
  swiperScreen.slideTo(index);
});
