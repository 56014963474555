


//menu open/close
$('.menu-open').on('click', function() {
  $(this).toggleClass('active');
  $('.menu').fadeToggle();
});
$('.menu-nav li').on('click', function() {
  $('.menu-open').removeClass('active');
  $('.menu').fadeOut();
});





$(window).on('load', function() {
  $('.preloader').delay(350).fadeOut('slow');
});
$('.intro__title span').delay(350).removeClass('active');
$('.load').delay(350).removeClass('active');

